import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Typography,
  Avatar,
  Paper,
  Chip,
  Divider,
  Box,
  Grid,
} from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { DatePicker } from "@mui/x-date-pickers";
import { getLotGagnants } from "../../../../redux/slices/generale";
import { fDateOnly } from "../../../../utils/formatTime";
import moment from "moment";

const LotGagnants = ({ open, handleClose }) => {
  const [date, setDate] = useState(new Date());
  const [lotGagnants, setLotGagnants] = useState([]);

  const fetchData = async () => {
    try {
      const data = await getLotGagnants(fDateOnly(date, "yyyy-MM-dd"));
      setLotGagnants(data);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, date]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Lot Gagnants</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Iconify icon={"eva:close-fill"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 2, py: 2 }}>
          <DatePicker
            label="Date"
            value={date}
            onChange={(newValue) => setDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <IconButton onClick={fetchData} aria-label="search">
            <Iconify icon={"eva:search-fill"} />
          </IconButton>
        </Box>

        <Box>
          {lotGagnants?.map((tirage, index) => (
            <Box key={index} py={1}>
              <Paper elevation={3} sx={{ borderRadius: 2 }}>
                <Grid container justifyContent="space-around" sx={{ p: 2 }}>
                  <Grid
                    item
                    xs={5}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      src={`https://sys-dgl.net/static/icons/${tirage?.id}.png`}
                      sx={{ width: 115, height: 115 }}
                      variant="square"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                  >
                    {tirage?.list?.map((item, idx) => (
                      <Box key={idx} width="100%">
                        <Typography
                          variant="h6"
                          align="center"
                          fontWeight="700"
                        >
                          {moment(item?.date).calendar(null, {
                            lastDay: "[Hier]",
                            sameDay: "[Aujourd'hui]",
                            nextDay: "[Demain]",
                            lastWeek: "dddd",
                            nextWeek: "dddd",
                            sameElse: "L",
                          })}{" "}
                          ( {item.name} )
                        </Typography>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Grid
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Chip
                              label={item.tirage_1}
                              sx={{
                                bgcolor: "#143fff",
                                color: "white",
                                fontWeight: "700",
                                m: 0.2,
                              }}
                            />
                            <Chip
                              label={item.tirage_2}
                              sx={{
                                bgcolor: "#ff8612",
                                color: "white",
                                fontWeight: "700",
                                m: 0.2,
                              }}
                            />
                            <Chip
                              label={item.tirage_3}
                              sx={{
                                bgcolor: "#00a0ea",
                                color: "white",
                                fontWeight: "700",
                                m: 0.2,
                              }}
                            />
                          </Grid>
                          <Grid
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            {item?.pick2 && (
                              <Chip
                                label={`P${item?.pick2}`}
                                sx={{
                                  bgcolor: "red",
                                  color: "white",
                                  fontWeight: "700",
                                  m: 0.2,
                                }}
                              />
                            )}
                            {item?.pick5 && (
                              <Chip
                                label={`P${item?.pick5}`}
                                sx={{
                                  bgcolor: "#7C6FFF",
                                  color: "white",
                                  fontWeight: "700",
                                  m: 0.2,
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                        {idx < tirage.list.length - 1 && (
                          <Divider sx={{ py: 0.5 }} />
                        )}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LotGagnants;
