// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Container,
  Typography,
  Tooltip,
} from "@mui/material";
// routes
import { getDeviceId } from "../../utils/uuidv4";
// hooks
import useAuth from "../../hooks/useAuth";
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
// sections
import { LoginForm } from "../../sections/auth/login";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const deviceId = getDeviceId();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo mini={mdUp} />
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Connectez-vous à Sys DGL
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Entrez vos coordonnées ci-dessous.
                </Typography>
              </Box>
            </Stack>

            <LoginForm />
            <CopyToClipboard
              text={deviceId}
              onCopy={() => enqueueSnackbar("Copié avec succès!")}
            >
              <Tooltip title="Copy">
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Serie : {deviceId}
                </Typography>
              </Tooltip>
            </CopyToClipboard>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
